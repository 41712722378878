import React from 'react'

const Contact = () => {
    return (
        <div className='-mt-12'>
            <div id='contact_header' className='header border-l-2 pl-2'>Contact</div>
            <div className='text-slate-400 leading-7 text-md'>jeffzhang0049@gmail.com</div>
        </div>
    )
}

export default Contact